<template>
  <div>
    <div class="breadcrumbs" v-if="sro && headerIsShow">
      <router-link :to="{ name: 'SroList' }" exact> Реестр СРО</router-link>
      /
      <router-link :to="{name: 'SroItem', id: sro.id}"> {{ sro.short_description }}</router-link>
      /
      <span> Реестр членов СРО </span>
    </div>
<!--    <div class="sro-item">-->
<!--      <div class="sro-item__title">{{ sro.full_description }}</div>-->
    <div
      class="page-title"
      v-if="sro && headerIsShow"
    >
      Реестр членов СРО &laquo;{{ sro.short_description }}&raquo;
    </div>
    <control-panel
      label-search="Рег. номер, ИНН, ОГРН, Наименование"
      v-bind:search-string.sync="options.searchString"
      :chips="chips"
      @show-filter="filterIsShow = true"
      @show-sort="sortIsShow = true"
      @submit-search="submitSearch"
      @delete-filter="deleteFilter"
    >
      <template v-slot:begin-buttons>
        <export-members
          v-if="sro"
          :sro-id="sro.id"
          :number-sro="sro.registration_number"
          :height="40"
          :width="40"
          @put-error-message="showError"
        >
          <template v-slot:progress>
            <v-progress-circular
              :size="24"
              color="primary"
              indeterminate
            >
            </v-progress-circular>
          </template>
          <template v-slot:generate>
            <v-icon style="font-size: 28px"
            >
              mdi-download
            </v-icon>
          </template>
          <template v-slot:download>
            <v-icon style="font-size: 28px"
            >mdi-download</v-icon>
          </template>
        </export-members>
      </template>
    </control-panel>

    <div
      style="position: relative"
      v-if="!errorMessage"
    >
      <sort-form
        :value="options.sortBy"
        :sort-options="filterOptions"
        :sort-is-show.sync="sortIsShow"
        @submit="submitSort"
      />
      <filter-form
        :filter-options="filterOptions"
        :filters="options.filters"
        :filter-is-show.sync="filterIsShow"
        @submit-filter="submitFilter"
      />
      <div
        v-if="list"
      >
        <div v-if="list.length > 0">
          <member-table-by-sro
            :search-string="options.searchString"
            :sort-by="options.sortBy"
            :list="list"
            :route-name="headerIsShow ? 'MemberItem' : 'FrameMemberItem'"
            @sort="sortBy"
          />
          <pagination
            :page-count="countPage"
            :page="page"
            :count-all="count"
            :max-count-on-page="options.pageCount"
            @check-page="setPage"
            @change-max-count="changeMaxCount"
          />
        </div>
        <div
          class="message"
          v-else
        >Записи, удовлетворяющие критериям поиска отсутствуют</div>
      </div>
      <spinner
        v-else-if="spinnerIsShow"
      />
    </div>
    <error-message
      v-else
    >{{ errorMessage }} </error-message>

  </div>
</template>

<script>
import ListMixin from '../../mixins/ListMixin'
import { FILTER_TYPE_EQ, FILTER_TYPE_LIKE, FILTER_TYPE_DATE } from '../../helpers/dictionary'
import { getDictionaries, getMemberListBySro, getSroItem } from '../../helpers/api'
import { saveMemberListBySroOptions, loadMemberListBySroOptions } from '../../helpers/history'
import FilterForm from '../../components/filter/FilterForm'
import MemberTableBySro from '../../components/member/MemberTableBySro'
import ControlPanel from '../../components/ControlPanel'
import ExportMembers from '../../components/buttons/ExportMembers'

export default {
  name: 'MemberListBySro',
  mixins: [ListMixin],
  components: {
    ExportMembers,
    ControlPanel,
    FilterForm,
    MemberTableBySro
  },
  data: () => ({
    sro: null,
    headerIsShow: false,
    filterOptions: [
      {
        name: 'region_number',
        title: 'Субъект РФ',
        type: FILTER_TYPE_EQ,
        values: []
      },
      {
        name: 'member_status',
        title: 'Статус',
        type: FILTER_TYPE_EQ,
        values: []
      },
      {
        name: 'registration_number',
        title: 'Регистрационный номер',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'short_description',
        title: 'Краткое наименование',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'registry_registration_date',
        title: 'Дата регистрации в реестре СРО',
        type: FILTER_TYPE_DATE
      },
      {
        name: 'ogrnip',
        title: 'ОГРН/ОГРНИП',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'inn',
        title: 'ИНН',
        type: FILTER_TYPE_LIKE
      },
      {
        name: 'director',
        title: 'ФИО руководителя',
        type: FILTER_TYPE_LIKE
      }
    ]
  }),
  methods: {
    async getDataList () {
      await this.getSro()
      return await getMemberListBySro(this.sro.id, this.options)
    },
    saveOptions: saveMemberListBySroOptions,
    loadOptions: loadMemberListBySroOptions,
    async getSro () {
      let requestData
      if (this.$route.query.path) {
        const path = atob(this.$route.query.path)
        this.headerIsShow = false
        requestData = await getSroItem(path.substring(path.indexOf('clients/') + 8, path.indexOf('/members')))
      } else {
        this.headerIsShow = this.$route.name !== 'ReestrFrame'
        requestData = await getSroItem(this.$route.params.id)
      }

      if (!this.validateRequestData(requestData)) {
        return
      }
      this.sro = requestData.data
    },
    async getDictionaries () {
      const requestData = await getDictionaries({ dictionaries: ['member_statuses', 'region_number'] })

      if (!this.validateRequestData(requestData)) {
        return
      }
      this.statusList = requestData.data.member_statuses
      this.regionList = requestData.data.region_number
      this.filterOptions.find(el => el.name === 'member_status').values = this.statusList
      this.filterOptions.find(el => el.name === 'region_number').values = this.regionList
    },
    showError (message) {
      this.errorMessage = message
    }
  }
}
</script>

<style scoped lang="sass">
</style>
