<template>
  <div class="table">
    <table class="table__list">
      <tr>
        <th @click="sort('full_description')" style="max-width: 100px">
          <div>
            <sort-icon :sort="sortBy.full_description"/>
            <div>Полное наименование</div>
          </div>
        </th>
        <th
          v-if="list[0] && !list[0].sro.deactivate_message"
          @click="sort('member_status')">
          <div>
            <sort-icon :sort="sortBy.member_status"/>
            <div>Статус</div>
          </div>
        </th>
        <th @click="sort('registration_number')">
          <div>
            <sort-icon :sort="sortBy.registration_number"/>
            <div>
              Рег. номер
            </div>
          </div>
        </th>
        <th @click="sort('registry_registration_date')">
          <div>
            <sort-icon :sort="sortBy.registry_registration_date"/>
            <div>
              Дата регистрации в реестре СРО
            </div>
          </div>
        </th>
        <th @click="sort('ogrnip')">
          <div>
            <sort-icon :sort="sortBy.ogrnip"/>
            <div>
              ОГРН/ОГРИП
            </div>
          </div>
        </th>
        <th @click="sort('inn')">
          <div>
            <sort-icon :sort="sortBy.inn"/>
            <div>
              ИНН
            </div>
          </div>
        </th>
        <th @click="sort('compensation_fund_fee_vv')">
          <div>
            <sort-icon :sort="sortBy.compensation_fund_fee_vv"/>
            <div>
              КФ ВВ
            </div>
          </div>
        </th>
        <th @click="sort('responsibility_level_vv')">
          <div>
            <sort-icon :sort="sortBy.responsibility_level_vv"/>
            <div>
<!--              Уровень ответственности ВВ-->
              Стоимость работ по одному договору, в соответствии
              с которым указанным членом внесен взнос в КФ ВВ
            </div>
          </div>
        </th>
        <th @click="sort('compensation_fund_fee_odo')">
          <div>
            <sort-icon :sort="sortBy.compensation_fund_fee_odo"/>
            <div>
              КФ ОДО
            </div>
          </div>
        </th>
        <th @click="sort('responsibility_level_odo')">
          <div>
            <sort-icon :sort="sortBy.responsibility_level_odo"/>
            <div>
<!--              Уровень ответственности ОДО-->
              Предельный размер обязательств по договорам, в соответствии
              с которым указанным членом внесен взнос в КФ ОДО
            </div>
          </div>
        </th>
      </tr>
      <tr
        v-for="member in list"
        :key="'member-' + member.id"
        @click="showMember(member.id)"
      >
        <td v-html="highlight(member.full_description)" />
        <td v-if="!member.sro.deactivate_message"
        >{{ member.member_status.title }}</td>
        <td v-html="highlight(member.registration_number)" />
        <td>{{ formatDateShort(member.registry_registration_date) }}</td>
        <td v-html="highlight(member.ogrnip)" />
        <td v-html="highlight(member.inn)" />
        <td>{{ member.compensation_fund_fee_vv && member.compensation_fund_fee_vv !== '0' ?
          formatPrice(member.compensation_fund_fee_vv) : 'не указано' }}
        </td>
        <td>{{ member.responsibility_level_vv ? member.responsibility_level_vv.cost : 'не указано' }}</td>
        <td>{{ member.compensation_fund_fee_odo && member.compensation_fund_fee_odo !== '0' ?
          formatPrice(member.compensation_fund_fee_odo) : 'не указано' }}
        </td>
        <td>{{ member.responsibility_level_odo ? member.responsibility_level_odo.cost : 'не указано' }}</td>
      </tr>
    </table>
    <div class="card-list">
      <div
        class="card"
        v-for="member in list"
        :key="'member-card-' + member.id"
      >
        <div class="card__header">
          <div>
            <div
                class="status"
                :class="{
                'status-empty': member.sro.deactivate_message,
                'status-enabled': member.member_status.code === $options.STATUS_ENABLED.id,
                'status-disabled': member.member_status.code === $options.STATUS_DISABLED.id
              }"
            ></div>
          </div>
          <div
            v-html="highlight(member.full_description)" />
          <div class="status"></div>
        </div>
        <div class="card__title">
          Регистрационный номер в реестре СРО:
        </div>
        <div class="card__value"
          v-html="highlight(member.registration_number)" />
        <div v-if="!member.sro.deactivate_message">
          <div class="card__title">
            Статус:
          </div>
          <div class="card__value">
            {{ member.member_status.title }}
          </div>
        </div>
        <div class="card__title">
          Дата регистрации в реестре СРО:
        </div>
        <div class="card__value">
          {{ formatDate(member.registry_registration_date) }}
        </div>
        <div class="card__title">
          ОГРН/ОГРНИП:
        </div>
        <div class="card__value"
          v-html="highlight(member.ogrnip)" />
        <div class="card__title">
          ИНН:
        </div>
        <div class="card__value"
          v-html="highlight(member.inn)" />
        <div class="card__title">
          КФ ВВ:
        </div>
        <div class="card__value">
          {{ member.compensation_fund_fee_vv && member.compensation_fund_fee_vv !== '0' ?
          formatPrice(member.compensation_fund_fee_vv) + ' рублей': 'не указано' }}
        </div>
        <div class="card__title">
<!--          Уровень ответственности ВВ:-->
          Стоимость работ по одному договору, в соответствии
          с которым указанным членом внесен взнос в КФ ВВ
        </div>
        <div class="card__value">
          {{ member.responsibility_level_vv ? member.responsibility_level_vv.cost : 'не указано' }}
        </div>
        <div class="card__title">
          КФ ОДО:
        </div>
        <div class="card__value">
          {{ member.compensation_fund_fee_odo && member.compensation_fund_fee_odo !== '0' ?
          formatPrice(member.compensation_fund_fee_odo) + ' рублей': 'не указано' }}
        </div>
        <div class="card__title">
<!--          Уровень ответственности ОДО:-->
          Предельный размер обязательств по договорам, в соответствии
          с которым указанным членом внесен взнос в КФ ОДО
        </div>
        <div class="card__value">
          {{ member.responsibility_level_odo ? member.responsibility_level_odo.cost : 'не указано' }}
        </div>
        <v-btn
          class="mt-5"
          color="primary"
          @click="showMember(member.id)"
        >
          Подробнее
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '../../helpers/format'
import TableMixin from '../../mixins/TableMixin'
import { MEMBER_STATUS_ENABLED, MEMBER_STATUS_DISABLED } from '../../helpers/dictionary'

export default {
  name: 'MemberTableBySro',
  props: {
    routeName: {
      type: String,
      default: 'MemberItem'
    }
  },
  mixins: [TableMixin],
  STATUS_ENABLED: MEMBER_STATUS_ENABLED,
  STATUS_DISABLED: MEMBER_STATUS_DISABLED,
  methods: {
    formatPrice,
    showMember (id) {
      this.$router.push({
        name: this.routeName,
        params: { id }
      })
    }
  }
}
</script>

<style scoped>

</style>
